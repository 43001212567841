import React, { Fragment } from "react"
import { Link, withRouter } from "react-router-dom"
import MainNavigation from "./../../MainNav/MainNav"
import { Image } from "./../../UI/Image/Image"
import { ArrowForward } from "./../../UI/Icons/Icons"
import { cruise } from "./../../../constants"
import "./Header.scss"

const Header = () => {
  return (
    <header className="header">
      <Hero />
      <MainNavigation />
    </header>
  )
}

const Hero = () => {
  return (
    <div className="hero">

      <HeroLinkAside render={false} />
      
      <div className="hero-nav">
        <NavItemHomeWithRouter />
      </div>

      <div className="hero-badge">
        <div className="hero-badge-heading">
          Kongress Schiffsreise
        </div>
        <div className="hero-badge-intro">
          {cruise.date}
        </div>
      </div>
      
      <div className="hero-image-wrapper">
        <div className="hero-gradient-text" />
        <Image
          className="hero-image"
          src={require('./../../../assets/images/HeroImage2xQ66.webp')}
          altText={cruise.ship}
        />
      </div>

    </div>
  )
}

const HeroLinkAside = ({ render }) => {
  if (render) {
    return (
      <div className="hero-link-aside">
        <a href="//www.kongress-schiffsreise.de/2019" className="header-button">
          Zur Kongress-Schiffsreise 2019
        </a>
      </div>
    )
  }
  return null
}

const NavItemHome = (props) => {
  const { location } = props
  
  if (location.pathname === "/") {
    return null
  }
  
  return (
    <Fragment>
      <Link to="/" className="hero-nav-item">
        <ArrowForward />
        Home
      </Link>
    </Fragment>
  )
}

const NavItemHomeWithRouter = withRouter(NavItemHome)

export default Header