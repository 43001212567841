export const perPersonLabel = "je Person"
export const perPersonPerNightLabel = "je Person je Nacht"
export const requestPriceLabel =  "Preis auf Anfrage"

export const cruise = {
  route:    "Rom – Mykonos – Izmir – Santorin – Neapel – Rom",
  date:     "18. Oktober 2024 – 25. Oktober 2024",
  ship:     "MSC Divina",
  shipLink: "https://www.msccruises.de/unsere-kreuzfahrten/kreuzfahrtschiffe/msc-divina",
  shippingCompanyLink: "https://www.msc-kreuzfahrten.de"
}

export const currencyFormatterLocale = { locale: 'de-DE' }

export const postServiceRecipientAgency = {
  development:  "andiw@zedat.fu-berlin.de",
  production:   "info@kongress-schiffsreise.de" // change this for testing purposes, eg. with dev email
}

export const postServiceRecipientClient = {
  development: "andiw@zedat.fu-berlin.de"
}

export const postServiceFrom = {
  development:  "From: Andreas Wagner <i.am.andreas.75@gmail.com>",
  production:   "From: Nadine Ramm <info@kongress-schiffsreise.de>"
}