import React, { Fragment } from "react"
import { SimpleTabs, SimpleTabsItem } from "../../UI/SimpleTabs/SimpleTabs"
import { RadioInput } from "../../UI/Input/Input"
import { Table } from "../../UI/Table/Table"
import { MiniButton, ButtonRow } from "../../UI/Button/Button"
import { List, ListItem } from "../../UI/List/List"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"
import { formatCabinPrice } from "../../../utils/utils"
import { cabins } from "../../../model/model"

const cabinsTableHead = [
  {
    name: "",
    width: "15%"
  },
  {
    name: "Kabinentyp",
    width: "35%"
  },
  {
    name: "Doppelbelegung",
    width: "25%"
  },
  {
    name: "3. und 4. Erwachsener",
    width: "25%"
  }
]

export const Cabins = ({values, errors}) => (
  <Fragment>
    <Table 
      headline="Kabine" 
      hasError={errors.cabin} 
      tableHead={cabinsTableHead}
    >
      <RenderCabinSection 
        section={cabins.innen} 
        values={values} 
      />
      <tr className="table-spacing-row" />
      <RenderCabinSection 
        section={cabins.balkon1} 
        values={values} 
      />
      <tr className="table-spacing-row" />
      <RenderCabinSection 
        section={cabins.balkon2} 
        values={values} 
      />
      <tr className="table-spacing-row" />
      <RenderCabinSection 
        section={cabins.premiumbalkon} 
        values={values} 
      />
      <tr className="table-spacing-row" />
      <RenderCabinSection 
        section={cabins.balkonaurea} 
        values={values} 
      />
      <tr className="table-spacing-row" />
      <RenderCabinSection 
        section={cabins.yachtclub} 
        values={values} 
      />
    </Table>
    <TableFooter />
  </Fragment>
)

const RenderCabinSection = ({section, values}) => {
  
  const cabinsList = section.map((cabin, index) => {
    const {name, label, price: {double, single}} = cabin
    const priceSingleCabinFormatted = formatCabinPrice(single, {type: "singleRoom"}) 
    const priceDoubleCabinFormatted = formatCabinPrice(double, {type: "doubleRoom"})

    return (
      <tr key={index}>
        <td>
          <RadioInput 
            className="justified"
            name="cabin"
            value={label}
            values={values}
            />
        </td>
        <td>
          <label htmlFor={label}>
            {name}
          </label>
        </td>
        <td>
          <label htmlFor={label}>
            {priceDoubleCabinFormatted}
          </label>
        </td>
        <td>
          <label htmlFor={label}>
            {priceSingleCabinFormatted}
          </label>
        </td>
      </tr>
    )
  })
  return cabinsList
}

const RenderCabinBellaClass = () => (
  <Fragment>
    <div className="padding-wide">
      <p>
        Mit der BELLA Erlebniswelt entdecken Ihre Kunden an jedem Tag ihrer Reise ein neues Zielgebiet. Während der Fahrt zum nächsten Hafen genießen sie an Bord den Komfort und Service eines eleganten modernen Kreuzfahrtschiffes. Ein tolles Preis-Leistungs-Verhältnis!
      </p>
      <p className="text-intro">
        Zielgruppe
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Junge Leute und andere Gäste, die keine besonderen Extras wünschen
        </ListItem>
        <ListItem hasDecoration={true}>
          Gäste, für die das Erlebnis "Kreuzfahrt" im Vordergrund steht
        </ListItem>
      </List>

      <p className="text-intro">
        Eingeschlossene Leistungen
      </p>

      <p className="text-bold text-medium">
        Buchungsflexibilität
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Umbuchung der Kreuzfahrt gegen Gebühr
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Komfort
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Moderne Schiffe und elegante Kabinen
        </ListItem>
        <ListItem hasDecoration={true}>
          MSC for Me (wo verfügbar)
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Speisen & Getränke
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Getränkepakete zum Vorteilspreis (nur zum Zeitpunkt der Buchung)
        </ListItem>
        <ListItem hasDecoration={true}>
          Buffetrestaurant mit vielfältigem Angebot
        </ListItem>
        <ListItem hasDecoration={true}>
          A-la-Carte Restaurants mit Service am Platz
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Sport & Aktivitäten
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Ausgezeichnete Shows im Broadway-Stil
        </ListItem>
        <ListItem hasDecoration={true}>
          Freie Nutzung des Poolbereichs
          </ListItem>
        <ListItem hasDecoration={true}>
          Open-Air-Sportanlagen
        </ListItem>
        <ListItem hasDecoration={true}>
          Moderne Fitnessstudios
        </ListItem>
        <ListItem hasDecoration={true}>
          Entertainment für Kinder und Erwachsene
        </ListItem>
        <ListItem hasDecoration={true}>
          Kinderbetreuung
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Serviceleistungen
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Mehr- und deutschsprachiger Gästeservice
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Privilegien
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          MSC Voyagers Club Punkte
        </ListItem>
      </List>
      <p className="text-small">
        *Für Kabinenservice sowie Frühstück in der Kabine fallen zusätzliche Kosten an. <br />
        **Die bevorzugte Tischzeit gilt vorbehaltlich der Verfügbarkeit und Bestätigung an Bord.<br />
        ***Spezielle Events für Kinder sind nicht inbegriffen (weitere Informationen direkt beim Mini Club).
      </p>
    </div>
    <HorizontalRule />
  </Fragment>
)

const RenderCabinFantasticaClass = () => (
  <Fragment>
    <div className="padding-wide">
      <p>
        Neben allen Leistungen der Erlebniswelt Bella beinhaltet die Erlebniswelt Fantastica eine kostenlose Umbuchung der Kreuzfahrt, freie Wahl der Tischzeit beim Abendessen, die Möglichkeit auf ein Frühstück in der Kabine ohne Aufpreis sowie 20% Rabatt auf ausgewählte Dinner-Erlebnisse in den Spezialitätenrestaurants (bei Vorausbuchung).
      </p>
      <p className="text-intro">
        Zielgruppe
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Familien oder andere Zielgruppen, die sich vorrangig erholen und entspannen möchten
        </ListItem>
        <ListItem hasDecoration={true}>
          Kunden, die größtmögliche Flexibilität wünschen
        </ListItem>
      </List>

      <p className="text-intro">
        Eingeschlossene Leistungen
      </p>

      <p className="text-bold text-medium">
        Buchungsflexibilität
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Wahl der Kabine*
        </ListItem>
        <ListItem hasDecoration={true}>
          Kreuzfahrt einmal kostenfrei umbuchbar**
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Komfort
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Moderne Schiffe und elegante Kabinen
        </ListItem>
        <ListItem hasDecoration={true}>
          MSC for Me (wo verfügbar)
        </ListItem>
        <ListItem hasDecoration={true}>
          24-Stunden-Kabinenservice (gegen Gebühr)
        </ListItem>
        <ListItem hasDecoration={true}>
          Frühstück auf der Kabine
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Speisen & Getränke
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Getränkepakete zum Vorteilspreis (nur zum Zeitpunkt der Buchung)
        </ListItem>
        <ListItem hasDecoration={true}>
          Buffetrestaurant mit vielfältigem Angebot
        </ListItem>
        <ListItem hasDecoration={true}>
          A-la-Carte Restaurants mit Service am Platz
        </ListItem>
        <ListItem hasDecoration={true}>
          Freie Wahl der Tischzeit (vorbehaltlich der Verfügbarkeit)
        </ListItem>
        <ListItem hasDecoration={true}>
          20% Rabatt auf ausgewählte Dinner-Erlebnisse in den Spezialitätenrestaurants (bei Vorausbuchung)
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Sport & Aktivitäten
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Ausgezeichnete Shows im Broadway-Stil
        </ListItem>
        <ListItem hasDecoration={true}>
          Freie Nutzung des Poolbereichs
        </ListItem>
        <ListItem hasDecoration={true}>
          Open-Air Sportanlagen
        </ListItem>
        <ListItem hasDecoration={true}>
          Moderne Fitnessstudios
        </ListItem>
        <ListItem hasDecoration={true}>
          Entertainment für Kinder und Erwachsene
        </ListItem>
        <ListItem hasDecoration={true}>
          Kinderbetreuung
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Serviceleistungen
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Deutschsprachiger Gästeservice
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Privilegien
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          MSC Voyagers Club Punkte
        </ListItem>
      </List>

      <p className="text-small">
        *Im Falle einer Kabinenänderung aus betrieblichen Gründen bekommen die Gäste ein Upgrade der Kabinenkategorie. <br />
        **Die kostenlose Kreuzfahrtumbuchung kann entweder auf die Reisedaten oder auf die Reiseroute angewendet werden. Die Umbuchung muss spätestens einen Monat vor der Abfahrt erfolgen. Die neue Kreuzfahrt muss innerhalb von drei Monaten nach der ursprünglichen Abfahrt stattfinden. Jede Umbuchung der Kreuzfahrt muss über unser Contact Center angefragt werden.
      </p>

    </div>
    <HorizontalRule />
  </Fragment>
)

const RenderAureaCabinClass = () => (
  <Fragment>
    <div className="padding-wide">
      <p>
        Mit dem Aurea Erlebnis wird die Kreuzfahrt Ihrer Kunden unvergesslich. Sie wählen nicht nur aus Balkonkabinen und Suiten in bester Lage auf den oberen Decks, sondern kommen zusätzlich in den Genuss exklusiver Vorteile. Neben allen Leistungen der Fantastica Erlebniswelt, erhalten Ihre Kunden beispielsweise eine bevorzugten Ein- und Ausschiffung. Auf ihrer Kabine warten bereits Prosecco und Pralinen sowie Bademäntel und Hausschuhe auf sie. Für mehr Privatsphäre während des Urlaubs sorgen der separate Bereich im Hauptrestaurant sowie das private Sonnendeck.
      </p>
      <p className="text-intro">
        Zielgruppe
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Gäste, die mehr Komfort und Wohnraum sowie exklusive Vorteile schätzen
        </ListItem>
      </List>

      <p className="text-intro">
        Eingeschlossene Leistungen
      </p>

      <p className="text-bold text-medium">
        Buchungsflexibilität
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Wahl der Kabine*
        </ListItem>
        <ListItem hasDecoration={true}>
          Kreuzfahrt einmal kostenfrei umbuchbar**
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Komfort
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Moderne Schiffe und elegante Kabinen
        </ListItem>
        <ListItem hasDecoration={true}>
          Suiten und Balkonkabinen in bester Lage
        </ListItem>
        <ListItem hasDecoration={true}>
          Willkommenspaket (Prosecco und Schokolade)
        </ListItem>
        <ListItem hasDecoration={true}>
          MSC for ME (wo verfügbar)
        </ListItem>
        <ListItem hasDecoration={true}>
          24-Stunden Kabinenservice
        </ListItem>
        <ListItem hasDecoration={true}>
          Frühstück auf der Kabine
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Speisen & Getränke
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Getränkepakete zum Vorteilspreis (nur zum Zeitpunkt der Buchung)
        </ListItem>
        <ListItem hasDecoration={true}>
          Buffetrestaurant mit vielfältigem Angebot
        </ListItem>
        <ListItem hasDecoration={true}>
          A-la-Carte Restaurants mit Service am Platz
        </ListItem>
        <ListItem hasDecoration={true}>
          My Choice Dining: Dinner ohne feste Tischzeiten in einem separaten Bereich
        </ListItem>
        <ListItem hasDecoration={true}>
          20% Rabatt auf ausgewählte Dinner-Erlebnisse in den Spezialitätenrestaurants (bei Vorausbuchung)
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Sport & Entertainment
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Ausgezeichnete Shows im Broadway-Stil
        </ListItem>
        <ListItem hasDecoration={true}>
          Frei Nutzung des Poolbereichs
        </ListItem>
        <ListItem hasDecoration={true}>
          Open-Air Sportanlagen
        </ListItem>
        <ListItem hasDecoration={true}>
          Moderne Fitnessstudios
        </ListItem>
        <ListItem hasDecoration={true}>
          Entertainment für Kinder und Erwachsene
        </ListItem>
        <ListItem hasDecoration={true}>
          Kinderbetreuung
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Entspannung & Wellness
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Freier Zutritt zum exklusiven TOP Sonnendeck
        </ListItem>
        <ListItem hasDecoration={true}>
          Bademantel und Hausschuhe in jeder Kabine
        </ListItem>
        <ListItem hasDecoration={true}>
          Kissenauswahl
        </ListItem>
        <ListItem hasDecoration={true}>
          Freier Zugang zum Thermalbereich (nur für Erwachsene)
        </ListItem>
        <ListItem hasDecoration={true}>
          40% Rabatt auf eine ausgewählte Spa-Anwendung (bei Vorausbuchung)
        </ListItem>
        <ListItem hasDecoration={true}>
          10% Rabatt auf alle Spa-Anwendungen (bei Buchung an Bord)
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Serviceleistungen
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Mehr- und deutschsprachiger Gästeservice
        </ListItem>
        <ListItem hasDecoration={true}>
          Priority Check-in*** und Gepäcktransport bei der Einschiffung
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Privilegien
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          MSC Voyagers Club Punkte
        </ListItem>
      </List>

      <p className="text-small">
        *Im Falle einer Kabinenänderung aus betrieblichen Gründen bekommen die Gäste ein Upgrade der Kabinenkategorie. <br />
        **Die kostenlose Kreuzfahrtumbuchung kann entweder auf die Reisedaten oder auf die Reiseroute angewendet werden. Die Umbuchung muss spätestens einen Monat vor der Abfahrt erfolgen. Die neue Kreuzfahrt muss innerhalb von drei Monaten nach der ursprünglichen Abfahrt stattfinden. Jede Umbuchung der Kreuzfahrt muss über unser Contact Center angefragt werden. <br />
        ***Der Priority Check-in ist aufgrund der teilweise eingeschränkten Infrastruktur vor Ort leider nicht in allen Häfen & Terminals möglich.
      </p>

    </div>
    <HorizontalRule />
  </Fragment>
)

const RenderYachtClub = () => (
  <Fragment>
    <div className="padding-wide">
      <p>
        Der MSC Yacht Club ist ein exklusives "Schiff-im-Schiff"-Konzept mit dem höchsten Maß an Privatsphäre, Luxus und persönlichem Service. Die MSC Yacht Club Kabinen befinden sich auf den oberen Vorderdecks
      </p>
      <p className="text-bold text-medium">
        Zielgruppe
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Gäste, die das Besondere suchen und sich auf einer Reise Exklusivität und Privatsphäre wünschen.
        </ListItem>
        <ListItem hasDecoration={true}>
          Gäste, die einen besonderen Anlass wie z.B. eine Hochzeitsreise, ein besonderes Jubiläum, einen Geburtstag, einen Abschluss, eine Beförderung oder einen Ruhestand feiern möchten.
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Vorteile
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Privater Eingang
        </ListItem>
        <ListItem hasDecoration={true}>
          Erstklassiger Service mit herausragenden Standards
        </ListItem>
        <ListItem hasDecoration={true}>
          24-Stunden Concierge-Rezeption
        </ListItem>
        <ListItem hasDecoration={true}>
          Exklusive Einrichtungen und Zusatzleistungen
        </ListItem>
        <ListItem hasDecoration={true}>
          Exklusives Gourmet Yacht Club Restaurant ohne feste Tischzeiten
        </ListItem>
        <ListItem hasDecoration={true}>
          Unterkunft in einer Superior Suite
        </ListItem>
        <ListItem hasDecoration={true}>
          A-la Carte Gerichte in exklusiven Restaurants
        </ListItem>
        <ListItem hasDecoration={true}>
          Zugang zu allen Schiffsbereichen, Entertainmentangeboten und Serviceleistungen
        </ListItem>
        
      </List>

      <p className="text-bold text-medium">
        Privilegien
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Priority Check-in und Check-out sowie bevorzugte Ein- und Ausschiffung bei den Landausflügen
        </ListItem>
        <ListItem hasDecoration={true}>
          24-Stunden-Butler- und Concierge-Service
        </ListItem>
        <ListItem hasDecoration={true}>
          All-Inclusive Getränkepaket Premium Extra*
        </ListItem>
        <ListItem hasDecoration={true}>
          Premium Internetpaket "Browse" mit unbegrenztem Datenvolumen für zwei Geräte
        </ListItem>
        <ListItem hasDecoration={true}>
          Freier Zugang zum Thermalbereich im MSC Aurea Spa
        </ListItem>
        <ListItem hasDecoration={true}>
          Bademantel und Hausschuhe in jeder Kabine
        </ListItem>
        <ListItem hasDecoration={true}>
          Kissenauswahl
        </ListItem>
        <ListItem hasDecoration={true}>
          Weitere Annehmlichkeiten auf Wunsch: Ein- und Auspacken der Koffer, Lieferung der Zeitung in die Kabine
        </ListItem>
        <ListItem hasDecoration={true}>
          MSC for Me Armband (wo verfügbar)
        </ListItem>
        <ListItem hasDecoration={true}>
          Kreuzfahrt einmal kostenfrei umbuchbar**
        </ListItem>
        <ListItem hasDecoration={true}>
          Willkommenspaket (Prosecco und Schokolade)
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Exklusivitäten
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Exklusiver privater Bereich, der nur für Gäste des MSC Yacht Club zugänglich ist
        </ListItem>
        <ListItem hasDecoration={true}>
          Großzügige Suiten mit einem hohen Maß an Komfort auf den Vorderdecks
        </ListItem>
        <ListItem hasDecoration={true}>
          Panorama-Top-Sail-Lounge mit Bar, Teeservice am Nachmittag, kleinen Snacks und Live-Musik am Abend
        </ListItem>
        <ListItem hasDecoration={true}>
          Exklusiver Poolbereich mit Sonnendeck, Whirlpools und Liegen sowie einer Bar mit bester Aussicht auf das Meer
        </ListItem>
        <ListItem hasDecoration={true}>
          Gourmetrestaurant mit a-la-carte Menü zum Frühstück, Mittag- und Abendessen ohne feste Tischzeiten
        </ListItem>
        <ListItem hasDecoration={true}>
          Auf Wunsch maßgeschneiderte Landausflüge und private Shoppingerlebnisse (nicht im Reisepreis eingeschlossen)
        </ListItem>
      </List>

      <p className="text-bold text-medium">
        Nutzung aller Einrichtungen an Bord und Teilnahme an allen Aktivitäten
      </p>
      <List className="text-small text-justify">
        <ListItem hasDecoration={true}>
          Bars, Lounges, Spezialitätenrestaurants, Pools etc.
        </ListItem>
        <ListItem hasDecoration={true}>
          Abwechslungsreiche Buffets
        </ListItem>
        <ListItem hasDecoration={true}>
          20% Rabatt auf ausgewählte Dinner-Erlebnisse in den Spezialitätenrestaurants (bei Vorausbuchung)
        </ListItem>
        <ListItem hasDecoration={true}>
          40% Rabatt auf eine ausgewählte Spa-Anwendung (bei Vorausbuchung)
        </ListItem>
        <ListItem hasDecoration={true}>
          10% Rabatt auf alle Spa-Anwendungen (bei Buchung an Bord)
        </ListItem>
        <ListItem hasDecoration={true}>
          Entertainment für Kinder und Erwachsene
        </ListItem>
        <ListItem hasDecoration={true}>
          Kinderbetreuung
        </ListItem>
        <ListItem hasDecoration={true}>
          Open-Air Sportanlagen
        </ListItem>
        <ListItem hasDecoration={true}>
          Moderne Fitnessstudios
        </ListItem>
      </List>

      <p className="text-small">
        *beinhaltet alle Getränke bis 13€ in allen Schiffsbereichen <br />
        **Die kostenlose Kreuzfahrtumbuchung kann entweder auf die Reisedaten oder auf die Reiseroute angewendet werden. Die Umbuchung muss spätestens einen Monat vor Abfahrt durchgeführt werden. Die neue Kreuzfahrt muss innerhalb von drei Monaten nach der ursprünglichen Abfahrt stattfinden. Jede Umbuchung der Kreuzfahrt muss über unser Contact Center angefragt werden.
      </p>

    </div>
    <HorizontalRule />
  </Fragment>
)

const RenderButtonRow = ({hidden = true}) => {
  if (hidden) {
    return null
  }
  return (
    <ButtonRow hasVerticalSpace={true}> 
      <MiniButton 
        file={true} 
        link="//www.kongress-schiffsreise.de/media/deckplan/deckplan.pdf"
      >
        Deckplan
      </MiniButton>
      <MiniButton 
        file={true} 
        link="//www.kongress-schiffsreise.de/media/deckplan/kabinengrundrisse.pdf"
      >
        Kabinengrundrisse
      </MiniButton>
    </ButtonRow>
  )
}

const TableFooter = () => (
  <Fragment>

    <List className="padding-wide text-small">
      <ListItem hasDecoration={true}>
        Bei allen Kabinenpreisen außer der Yacht-Club-Suiten, ist das Getränkepaket All-Inclusive-Easy im Wert von 39,-€ pro Tag inkludiert. Bei allen Kabinenpreisen ist die Hotelservicegebühr von 12,-€ pro Tag inkludiert. Bei den Kabinenpreisen der Yacht-Club-Suiten gelten gesonderte Getränke Regelungen.
      </ListItem>
      <ListItem hasDecoration={true}>
        Bitte beachten Sie, dass wir erst mit dem Buchungsprozess beginnen können, nachdem uns das vollständig ausgefüllte Bordmanifest vorliegt.
      </ListItem>
      <ListItem hasDecoration={true}>
        *Die genannten Kabinenpreise gelten nur bei Verfügbarkeit.
      </ListItem>
      <ListItem hasDecoration={true}>
        Bei Kabinen der Erlebniswelt BELLA handelt es sich ausschließlich um Garantiekabinen, die in einem Gruppenkontigent zudem ausschließlich maximal zur Doppelbelegung buchbar sind. Es können weder Kabinennummer noch Deck ausgewählt werden und möglicherweise wird unseren Gästen eine Kabinen mit Sichteinschränkung, teilweise Sichteinschränkung oder Promenadenblick (nur auf bestimmten Schiffen) zugewiesen. Einzel- sowie 3er und 4er-Belegungungen können nur in den Erlebniswelten FANTASTICA und AUREA auf Anfrage gebucht werden.
      </ListItem>
      <ListItem hasDecoration={true}>
        Kabinenwünsche sind in der Erlebniswelt BELLA nicht möglich und werden in den Erlebniswelten FANTASTICA und AUREA gerne vermerkt, können jedoch nicht garantiert werden. Die Kabinengröße innerhalb eines Kabinentyps kann variieren. Die GFB hat weder Einfluss auf eine bestimmte Kabinennummer noch auf die Deckkategorie.
      </ListItem>
    </List>

    <HorizontalRule />

    <SimpleTabs
      tabsBarClassName="simple-tabs-tabbar" 
      tabsBarItemClassName="simple-tabs-tabbar-item"
    >
      <SimpleTabsItem name="Erlebniswelt BELLA">
        <RenderCabinBellaClass />
      </SimpleTabsItem>
      <SimpleTabsItem name="Erlebniswelt FANTASTICA">
        <RenderCabinFantasticaClass />
      </SimpleTabsItem>
      <SimpleTabsItem name="Erlebniswelt AUREA">
        <RenderAureaCabinClass />
      </SimpleTabsItem>
      <SimpleTabsItem name="Erlebniswelt YACHT CLUB">
        <RenderYachtClub />
      </SimpleTabsItem>
    </SimpleTabs>
    
    <RenderButtonRow />

  </Fragment>
)

