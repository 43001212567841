import React from 'react'
import { Table } from "../UI/Table/Table"

const tableHead = [
  {
    name: "Tag",
    width: "20%",
    align: "center"
  },
  {
    name: "Routenverlauf",
    width: "30%"
  },
  {
    name: "Ankunft",
    width: "25%"
  },
  {
    name: "Abfahrt",
    width: "25%"
  }
]

export const DestinationDates = () => {
  return (
    <div className="padding-wide">
      <div className="padding-wide">
        <Table
          hasError={null}
          headline="Reiseplan"
          tableHead={tableHead}
        >
          <tr>
            <td className="text-center">Fr. 18.10.24</td>
            <td>Civitavecchia (Rom), Italien</td>
            <td></td>
            <td>17:00 Uhr</td>
          </tr>

          <tr>
            <td className="text-center">Sa. 19.10.24</td>
            <td>Auf See</td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td className="text-center">So. 20.10.24</td>
            <td>Mykonos, Griechenland</td>
            <td>13:00 Uhr</td>
            <td>23:00 Uhr</td>
          </tr>

          <tr>
            <td className="text-center">Mo. 21.10.24</td>
            <td>Izmir, Türkei</td>
            <td>09:00 Uhr</td>
            <td>17:00 Uhr</td>
          </tr>

          <tr>
            <td className="text-center">Di. 22.10.24</td>
            <td>Santorin, Griechenland</td>
            <td>08:00 Uhr</td>
            <td>18:00 Uhr</td>
          </tr>

          <tr>
            <td className="text-center">Mi. 23.10.24</td>
            <td>Auf See</td>
            <td></td>
            <td></td>
          </tr>

          <tr>
            <td className="text-center">Do. 24.10.24</td>
            <td>Neapel (Pompei), Italien</td>
            <td>11:00 Uhr</td>
            <td>20:00 Uhr</td>
          </tr>

          <tr>
            <td className="text-center">Fr. 25.10.23</td>
            <td>Civitavecchia (Rom), Italien</td>
            <td>07:00 Uhr</td>
            <td></td>
          </tr>
        </Table>
      </div>
    </div>
  )
}