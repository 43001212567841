import React, { Fragment } from "react"
import { Cabins } from "./FormInput/CruiseFormInputCabins"
import { Insurance } from "./FormInput/CruiseFormInputInsurance"
import { InsurancePremiumProtection } from "./FormInput/CruiseFormInputInsurancePremiumProtection";
import { Passengers } from "./FormInput/CruiseFormInputPassengers"
import { FellowTravellers } from "./FormInput/CruiseFormInputFellowTraveller"
import { BillingAddress } from "./FormInput/CruiseFormInputBillingAddress"
import { Drinks } from "./FormInput/CruiseFormInputDrinks"
import Transfer from "./FormInput/CruiseFormInputTransfer"
import HorizontalRule from "./../UI/HorizontalRule/HorizontalRule"
import { List, ListItem } from "./../UI/List/List"
import { ButtonRow, MiniButton } from "../UI/Button/Button";
import { ExternalLink } from "./../UI/Link/Link"
import { CruiseDate, CruiseShip, CruisingCompany, CruiseRoute } from "./../Content/Content"
import "./Cruise.scss"

const CruiseFormInput = (props) => {
  if (process.env.NODE_ENV === "development") {
    console.log(props.values)
  }
  
  return (
    <Fragment>
      <FormHeader />
      <Cabins {...props} />
      <Transfer />
      <Insurance {...props} />
      <InsurancePremiumProtection {...props} />
      <Drinks {...props} />
      <Passengers {...props} />
      <FellowTravellers />
      <BillingAddress {...props} />
      <FormFooter {...props} />
    </Fragment>
  )
}

const FormHeader = () => (
  <Fragment>
    <h1 className="h1 text-center">
      Reiseanmeldung
    </h1>
    <HorizontalRule type="wave" />
    <p className="text-intro text-center">
      Veranstalter und Schiff: <CruisingCompany className="link-simple" />, <CruiseShip className="link-simple" /> &bull; Route: <CruiseRoute /> &bull; <CruiseDate />
    </p>
    <HorizontalRule type="wave" />
    <p className="text-center text-grey">
      Bitte füllen Sie die Formularfelder aus. 
    </p>
  </Fragment>
)

const FormFooter = (props) => {
  return (
    <Fragment>
      <List className="padding-wide text-small text-justify">
        <ListItem hasDecoration>
          Anmeldung und Bestätigung erfolgen auf Basis der Reisebedingungen der Reederei MSC Cruises S.A., 40, Eugene Pittard, 1206 Genf (Schweiz), Ansprechpartner in Deutschland ist MSC Kreuzfahrten GmbH, Ridlerstrasse 37, 80339 München.
        </ListItem>
        <ListItem hasDecoration>
          Der Umfang der vertraglichen Leistungen ergibt sich aus dieser Reiseanmeldung und der Leistungsbeschreibung in dem für diese Buchung maßgeblichen Katalog. Es gelten des Weiteren die 
          <ExternalLink 
            hasSpaces={true}
            className="link-underline"
            link="//www.msc-kreuzfahrten.de/de-de/Allgemeine-Geschaeftsbedingungen.aspx"
          >
            Allgemeinen Geschäftsbedingungen
          </ExternalLink>
          (AGB) der Reederei.
        </ListItem>
        <ListItem hasDecoration>
          Wir weisen darauf hin, dass wir nur als Vermittler zwischen Ihnen und der oben genanntem Reederei auftreten. Es wurde des Weiteren darauf hingewiesen, eine Reiserücktrittsversicherung abzuschließen.
        </ListItem>
        <ListItem hasDecoration>
          Mit Ihrer Unterschrift erkennen Sie, zugleich für alle angemeldeten Teilnehmer, die Reisebedingungen des Veranstalters und die Beförderungsbedingungen der beteiligten Verkehrsträger als verbindlich an.
        </ListItem>
      </List>
      <HorizontalRule />
      <RenderNext {...props} />
    </Fragment>
  )
}

const RenderNext = ({isSubmitting, isValid}) => {
  return (
    <Fragment>
      { !isValid &&
        <p className="text-center text-small text-warning">
          Bitte füllen Sie die markierten Formularfelder aus.
        </p>
      }
      <ButtonRow hasVerticalSpace={true}>
        <MiniButton 
          link="/cruise/verify"
          className="mini-button"
          large={true}
          disabled={isSubmitting || !isValid}
        >
          Weiter
        </MiniButton>
      </ButtonRow>
      <HorizontalRule />
    </Fragment>
  )
}

export default CruiseFormInput