import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"
import { TextInput } from "../../UI/Input/Input"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"

export const Cabin = () => (
  <Fragment>
    <Table 
      hasError={null}
      headline="Ihre Reservierungs- &amp; Kabinennummer"
    >
      <tr>
        <td
          width="15%" 
          className="text-center">
          Reservierungs-Nr.:	
        </td>
        <td>
          <TextInput 
            name="reservationNumber"
            className="one-third-width"
          />
        </td>
      </tr>
      <tr>
        <td className="text-center">
          Kabinen-Nr.:		
        </td>
        <td>
          <TextInput 
            name="cabinNumber"
            className="one-third-width"
          />
        </td>
      </tr>
    </Table>
    <TableFooter />
  </Fragment>
)

const TableFooter = () => (
  <Fragment>
    <p className="padding-wide text-small text-center">
      Die Angaben zu Reservierungs- und Kabinen-Nr. sind optional.
    </p>
    <HorizontalRule />
  </Fragment>
)