import React, { Fragment } from "react"
import { TextInput } from "../../UI/Input/Input"
import { Table } from "../../UI/Table/Table"
import { List, ListItem } from "../../UI/List/List"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"

const passengersTableHead = [
  {
    name: "",
    width: "15%"
  },
  {
    name: "Name",
    width: "30%"
  },
  {
    name: "Vorname",
    width: "30%"
  },
  {
    name: "Geburtsdatum",
    width: "25%"
  }
]

export const Passengers = ({errors}) => { 
  const hasError = ["surname", "lastname", "dateOfBirth"].some(value => {
    return errors && errors.passenger && errors.passenger[value]
  })
  
  return (
    <Fragment>
      <Table
        headline="Teilnehmer"
        hasError={hasError}
        tableHead={passengersTableHead}
      >
        <RenderPassengers count={4} />
      </Table>
      <TableFooter />
    </Fragment>
  )
}

const RenderPassengers = ({count}) => {
  const passengers = [...Array(count).keys()].map((passenger, index) => {
    return (
      <tr key={index}>
        <td className="text-center">
          {index + 1} 
        </td>
        <td>
          <TextInput 
            name={index === 0 ? `passenger.lastname` : `optionalPassengers[${index-1}][lastname]`}
            className="full-width"
            placeholder="Name"
            hasError={index === 0 ? true : false}
          />   
        </td>
        <td>
          <TextInput 
            name={index === 0 ? `passenger.surname` : `optionalPassengers[${index-1}][surname]`}
            className="full-width"
            placeholder="Vorname"
            hasError={index === 0 ? true : false}
          />      
        </td>
        <td>
          <TextInput 
            name={index === 0 ? `passenger.dateOfBirth` : `optionalPassengers[${index-1}][dateOfBirth]`}
            className="full-width"
            placeholder="tt.mm.jjjj"
            hasError={index === 0 ? true : false}
          />   
        </td>
      </tr>
    )
  })
  return passengers
}

const TableFooter = () => (
  <Fragment>
    <List className="padding-wide text-small text-justify">
      <ListItem hasDecoration={true}>
        Einzelbettbelegungen, 3- oder 4- Bettbelegungen sind grundsätzlich auf Anfrage und müssen von der Reederei bestätigt werden. Der Einzelkabinenzuschlag beträgt 100% des Kabinenpreises. Für Suiten gelten Sonderregelungen. Kinder 2 - 11,99 Jahre als 3./4. Person ab 105,-€ p.P. Kinder 12 - 17,99 Jahre als 3./4. Person ab 140,-€ p.P.
      </ListItem>
      <ListItem hasDecoration={true}>
        Trinkgelder am Bord: Auf allen MSC - Schiffen ist es üblich, Crew-Mitgliedern in Ihrem Ermessen Trinkgelder zu geben. MSC empfehlen den üblichen Trinkgeldbetrag pro Person und Nacht von 10,00 EUR (ab 12 Jahre). Kinder ab 2 Jahre zahlen 5,00 EUR pro Nacht und Kind. Für Babys bis 2 Jahre wird keine Servicecharge erhoben. Um eine komfortable, diskrete Abwicklung zu ermöglichen, wird die Trinkgeldempfehlung bereits auf Ihrer Bordrechnung vorgemerkt. Wenn Sie es wünschen, können Sie diesen Betrag nach eigenem Belieben am Guest Service Desk streichen oder anpassen lassen.
      </ListItem>
    </List>
    <HorizontalRule />
  </Fragment>
)