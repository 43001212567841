import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"

const VerifyCabin = ({values}) => {
  const { reservationNumber, cabinNumber } = values

  if (!reservationNumber && !cabinNumber) {
    return null
  }

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="Ihre Reservierungs- &amp; Kabinennummer"
      >
        <RenderItem label="Reservierungs-Nr.:" value={reservationNumber} />
        <RenderItem label="Kabinen-Nr.:" value={cabinNumber} />
      </Table>
    </Fragment>
  )
}

const RenderItem = ({label, value}) => {
  if (!value) {
    return null
  }

  return (
    <tr>
      <td 
        width="15%"
        className="text-center"  
      >
        {label}
      </td>
      <td>
        {value}
      </td>
    </tr>
  )
}

export default VerifyCabin