import { travelDocumentTypes } from "../model/model"
import { cruise } from "../constants"
import { formatText } from "../utils/utils"

export const createManifestMailText = (values, type = "client") => {
  const getIntroTextClient = () => {
    return formatText()
      .p("Sehr geehrte Damen und Herren,")
      .l(`vielen Dank für das Ausfüllen des Bordmanifests für die Kongress-Schiffsreise am ${cruise.date} auf der Route ${cruise.route}. Unten finden Sie noch einmal ihre Anmeldedaten zusammengefasst.`)
      .finalize()
    }

  const getIntroTextAgency = () => {
    return formatText()
      .p("Sehr geehrte Damen und Herren,")
      .l(`ein Bordmanifest für die Kongress-Schiffsreise am ${cruise.date} auf der Route ${cruise.route} wurde verschickt. Unten finden Sie die zugehörigen Formulardaten.`)
      .finalize()
    }

  const getPersonalDataText = () => {
    const { passenger: { surname, lastname, dateOfBirth, placeOfBirth, nationality }, optionalPassengers } = values
    const filteredPassengers = optionalPassengers
      .filter(passenger => (passenger.surname && passenger.lastname && passenger.dateOfBirth && passenger.placeOfBirth && passenger.nationality))
      .map(passenger => (
        formatText()
          .l(`${passenger.surname} ${passenger.lastname}`)
          .l(`Geburtsdatum: ${passenger.dateOfBirth}`)
          .l(`Geburtsort:   ${passenger.placeOfBirth}`)
          .l(`Nationalität: ${passenger.nationality}`)
          .finalize()
      )).join("\n")
      
    return formatText()
      .p("Persönliche Angaben:")
      .l(`${surname} ${lastname}`)
      .l(`Geburtsdatum: ${dateOfBirth}`)
      .l(`Geburtsort:   ${placeOfBirth}`)
      .l(`Nationalität: ${nationality}`)
      .br()
      .l(filteredPassengers)
      .finalize()
  }

  const getDocumentsDataText = () => {
    const { travelDocument: { issueDate, issuePlace, number, type, validUntil }, optionalTravelDocuments } = values
    const filteredDocs = optionalTravelDocuments
      .filter(doc => (doc.issueDate && doc.issuePlace && doc.number && doc.type && doc.validUntil))
      .map((doc, index) => (
        formatText()
          .p(`Teilnehmer ${index + 2}:`)
          .l(`Reisedokument:      ${travelDocumentTypes[doc.type].text}`)
          .l(`Reisedokument-Nr.:  ${doc.number}`)
          .l(`Ausstellungsort:    ${doc.issuePlace}`)
          .l(`Ausstellungsdatum:  ${doc.issueDate}`)
          .l(`Gültig bis:         ${doc.validUntil}`)
          .finalize()
      )).join("\n")

    return formatText()
      .p("Teilnehmer 1:")
      .l(`Reisedokument:      ${travelDocumentTypes[type].text}`)
      .l(`Reisedokument-Nr.:  ${number}`)
      .l(`Ausstellungsort:    ${issuePlace}`)
      .l(`Ausstellungsdatum:  ${issueDate}`)
      .l(`Gültig bis:         ${validUntil}`)
      .br()
      .l(filteredDocs)
      .finalize()
  }

  const getEmergencyText = () => {
    const { emergency: { name, phone }} = values
    return formatText()
      .p("Notfallkontakt:")
      .l(name)
      .l(phone)
      .finalize()
  }

  return formatText()
    .l(type === "client" ? getIntroTextClient() : getIntroTextAgency())
    .t(getPersonalDataText())
    .t(getDocumentsDataText())
    .l(getEmergencyText())
    .finalize()
}