import React, { Fragment } from "react"
import { Table } from "../../UI/Table/Table"

const VerifyEmergency = ({values}) => {
  const { emergency: { name, phone } } = values

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="Im Notfall ist folgende Person zu benachrichtigen"
      >
        <RenderItem label="Name" value={name} />
        <RenderItem label="Telefonnummer" value={phone} />
      </Table>
    </Fragment>
  )
}

const RenderItem = ({label, value}) => {
  if (!value) {
    return null
  }

  return (
    <tr>
      <td 
        width="15%"
        className="text-center"  
      >
        {label}
      </td>
      <td>
        {value}
      </td>
    </tr>
  )
}

export default VerifyEmergency