import React, { Fragment } from "react"
import { Table, TableIcon } from "../../UI/Table/Table"
import { formatPrice, formatPriceWithPrefix } from "../../../utils/utils"
import { transfersModel } from "../../../model/model"
import { perPersonLabel } from "../../../constants"

const VerifyTransfer = ({values}) => {
  
  const { transfer, transfer: { noBookingNeeded } } = values
  const filteredTransfer = (values && values.transfer && values.transfer.type) ? transfersModel.filter((transfer) => transfer.label === values.transfer.type)[0] : null
  
  const RenderTransfer = () => {
    if (noBookingNeeded) {
      return (
        <td>
          Ich wünsche keine Buchung der An- und Abreise.
        </td>
      )
    }

    const transferDestination = filteredTransfer.hasDestinationProvidedByUser ? `${filteredTransfer.text}: ${transfer.destination}` : filteredTransfer.text
    const transferPrice = filteredTransfer.hasDestinationProvidedByUser ? formatPriceWithPrefix(filteredTransfer.price, filteredTransfer.pricePrefix, filteredTransfer.priceSuffix) : formatPrice(filteredTransfer.price, perPersonLabel)
  
    return (
      <Fragment>
        <td width="35%">
          {transferDestination}
        </td>
        <td>
          {transferPrice}
        </td>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <Table  
        hasError={null}
        headline="Buchung der An- und Abreise"
      >
        <tr>
          <td width="15%">
            <TableIcon type={noBookingNeeded ? "cross" : "check"} />
          </td>
          <RenderTransfer />
        </tr>
      </Table>
    </Fragment>
  )
}

export default VerifyTransfer