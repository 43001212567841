import React, { Fragment } from "react"
import { Cabin } from "./FormInput/Cabin"
import { PersonalData } from "./FormInput/PersonalData"
import { TravelDocument } from "./FormInput/TravelDocument"
import { Emergency } from "./FormInput/Emergency";
import HorizontalRule from "../UI/HorizontalRule/HorizontalRule"
import { ButtonRow, MiniButton } from "../UI/Button/Button";
import { CruiseDate, CruiseShip, CruiseRoute, CruisingCompany } from "../Content/Content"

const ManifestFormInput = (props) => {
  if (process.env.NODE_ENV === "development") {
    console.log(props.values)
  }
  
  return (
    <Fragment>
      <FormHeader />
      <Cabin {...props} />
      <PersonalData {...props} />
      <TravelDocument {...props} />
      <Emergency {...props} />
      <FormFooter {...props} />
    </Fragment>
  )
}

const FormHeader = () => (
  <Fragment>
    <h1 className="h1 text-center">
      Bordmanifest
    </h1>
    <HorizontalRule type="wave" />
    <p className="text-intro text-center">
      Veranstalter und Schiff: <CruisingCompany className="link-simple" />, <CruiseShip className="link-simple" /> &bull; Route: <CruiseRoute /> &bull; <CruiseDate />
    </p>
    <HorizontalRule type="wave" />
    <p className="padding-wide text-center text-grey">
      Bitte füllen Sie das Bordmanifest aus. Das Bordmanifest muss für alle Passagiere (auch Kinder) vollständig mit allen im Reisepass angegebenen Namen ausgefüllt werden. 
    </p>
  </Fragment>
)

const FormFooter = (props) => {
  return (
    <Fragment>
      <RenderNext {...props} />
    </Fragment>
  )
}

const RenderNext = ({isSubmitting, isValid}) => {
  return (
    <Fragment>
      { !isValid &&
        <p className="text-center text-small text-warning">
          Bitte füllen Sie die markierten Formularfelder aus.
        </p>
      }
      <ButtonRow hasVerticalSpace={true}>
        <MiniButton 
          link="/manifest/verify"
          className="mini-button"
          large={true}
          disabled={isSubmitting || !isValid}
        >
          Weiter
        </MiniButton>
      </ButtonRow>
      <HorizontalRule />
    </Fragment>
  )
}

export default ManifestFormInput