export const cruiseModel = {
  im1: {
    label: "im1",
    name: "Junior Innenkabine (BELLA)",
    price: {
      single: null,
      double: 949
    }
  },
  ir1: {
    label: "ir1",
    name: "Deluxe Innenkabine (FANTASTICA)",
    price: {
      single: null,
      double: 979
    }
  },
  ir2: {
    label: "ir2",
    name: "Deluxe Innenkabine (FANTASTICA)",
    price: {
      single: null,
      double: 1009
    }
  },
  ol1: {
    label: "ol1",
    name: "Premium* Kabine mit Meerblick (FANTASTICA)",
    price: {
      single: null,
      double: 1139
    }
  },
  bp: {
    label: "bp",
    name: "Deluxe Balkonkabine (teilweise Sichteinschränkung)",
    price: {
      single: null,
      double: 1239
    }
  },
  bm1: {
    label: "bm1",
    name: "Junior Balkonkabine (BELLA)",
    price: {
      single: null,
      double: 1249
    }
  },
  bm2: {
    label: "bm2",
    name: "Junior Balkonkabine (BELLA)",
    price: {
      single: null,
      double: 1269
    }
  },
  bl1: {
    label: "bl1",
    name: "Premium* Balkonkabine (FANTASTICA)",
    price: {
      single: null,
      double: 1289
    }
  },
  bl2: {
    label: "bl2",
    name: "Premium* Balkonkabine (FANTASTICA)",
    price: {
      single: null,
      double: 1299
    }
  },
  bl3: {
    label: "bl3",
    name: "Premium* Balkonkabine (FANTASTICA)",
    price: {
      single: null,
      double: 1329
    }
  },
  ba: {
    label: "ba",
    name: "Balkonkabine* (AUREA)",
    price: {
      single: null,
      double: 1479
    }
  },
  yc1: {
    label: "yc1",
    name: "Yacht Club Deluxe Suite*",
    price: {
      single: null,
      double: 2459
    }
  }
}

const { im1, ir1, ir2, ol1, bp, bm1, bm2, bl1, bl2, bl3, ba, yc1 } = cruiseModel

export const cabins = {
  innen: [
    im1,
    ir1,
    ir2
  ],
  balkon1: [
    ol1,
    bp
  ],
  balkon2: [
    bm1,
    bm2
  ],
  premiumbalkon: [
    bl1,
    bl2,
    bl3
  ], 
  balkonaurea: [
    ba
  ],
  yachtclub: [
    yc1
  ]
}

export const transfersModel = [
  {
    label: "transferByFlight",
    text: "Per Flug, ab",
    price: null,
    pricePrefix: "Flüge stehen zur Verfügung ab Dresden, Bremen, Düsseldorf, Köln, Stuttgart, Hamburg, Hannover, München und Berlin zum Preis von 400 bis 600 EUR.",
    priceSuffix: " Die Preise der Fluggesellschaften sind tageszeitabhängig und können stündlich variieren, die GFB übernimmt keine Gewähr.",
    hasDestinationProvidedByUser: true
  }
]

export const drinksOptions = [
  {
    label: "easy",
    name: "All-Inclusive Getränkepaket Easy",
    description: "Das All-Inclusive Getränkepaket EASY im Wert von 312,00 EUR inkludiert. Unbegrenzter Genuss alkoholfreier und alkoholischer Getränke. Ausgewählte Weine & Prosecco (glasweise, Weiß-, Rot- und Roséwein), Heineken Fassbier, Auswahl an alkoholischen und alkoholfreien Cocktails, Softdrinks und Fruchtsäfte im Glas, Mineralwasser in der Flasche und klassische Heißgetränke (Espresso, Cappuccino, Café Latte, Tees etc.). Die im Paket enthaltenen Getränke können in fast allen Bars, Buffet-Restaurants, Restaurants verzehrt werden. Das Paket gilt nicht in Spezialitätenrestaurants.",
    priceAdults: 0
  },
  {
    label: "easy-plus",
    name: "All-Inclusive Getränkepaket EASY PLUS",
    description: "Ich wünsche das All-Inclusive Getränkepaket EASY PLUS mit einem Aufpreis von 84,00 EUR p.P. Reichhaltige Auswahl alkoholfreier und alkoholischer Getränke. Fass- und Flaschenbier, breite Auswahl an Cocktails, Spirituosen, Likören und Mischgetränken, Auswahl an verschiedenen Weinen und Prosecco im Glas (Weiß-, Rot- und Roséwein), 10% Ermäßigung auf Flaschenweine, Auswahl an alkoholischen und alkoholfreien Cocktails, Softdrinks und Fruchtsäfte im Glas, Energy Drinks, Mineralwasser aus der Flasche, Klassische Heißgetränke (Espresso, Cappuccino, Café Latte, Tees etc.) Alle Getränke der Barkarte bis zu einem Preis von je € 8,- . Die im Paket enthaltenen Getränke können in fast allen Bars, Buffet-Restaurants, Restaurants sowie in den Spezialitätenrestaurants verzehrt werden.",
    priceAdults: 84
  },
  {
    label: "premium-extra",
    name: "All-Inclusive Getränkepaket PREMIUM EXTRA",
    description: "Ich wünsche das All-Inclusive Getränkepaket PREMIUM EXTRA mit einem Aufpreis von 210,00 EUR p.P. Reichhaltige Auswahl alkoholfreier und alkoholischer Getränke. Große Auswahl an alkoholischen und alkoholfreien Cocktails, Markenspirituosen, Liköre und Mischgetränke, große Auswahl an Weinen und Champagner im Glas, alle Getränke in den Themenbars, 25% Rabatt auf Wein- und Champagnerflaschen, die an Bord erworben werden, Auswahl an Fass-, Craft- und Flaschenbieren, Softdrinks und Fruchtsäfte, Energy Drinks, Mineralwasser aus der Flasche, Heißgetränke (Espresso, Cappuccino, Latte Macchiato, Tees etc.). Alle Getränke der Barkarte bis zu einem Preis von je € 13,- . Die im Paket enthaltenen Getränke können in fast allen Bars, Buffet-Restaurants, Restaurants sowie in den Spezialitätenrestaurants verzehrt werden.",
    priceAdults: 210
  }
]

export const congressAttendeeRoles = {
  dentist: {
    label:      "dentist",
    textMale:   "Zahnarzt",
    textFemale: "Zahnärztin",
    textPlural: "Zahnärztinnen, Zahnärzte",
    textSingular: "Zahnärztin, Zahnarzt"
  },
  assistantDentist: {
    label:      "assistantDentist",
    textMale:   "Assistenzzahnarzt",
    textFemale: "Assistenzzahnärztin",
    textPlural: "Assistenzzahnärztinnen, Assistenzzahnärzte",
    textSingular: "Assistenzzahnärztin, Assistenzzahnarzt"
  },
  technician: {
    label:      "technician",
    textMale:   "Zahntechniker",
    textFemale: "Zahntechnikerin",
    textPlural: "Zahntechnikerinnen, Zahntechniker",
    textSingular: "Zahntechnikerin, Zahntechniker"
  },
  assistant: {
    label:      "assistant",
    textMale:   "Zahnmedizinischer Fachangestellter",
    textFemale: "Zahnmedizinische Fachangestellte",
    textPlural: "Zahnmedizinische Fachangestellte",
    textSingular: "Zahnmedizinische(r) Fachangestellte(r)"
  }
}

export const congressPrice = {
  dentist: 940,
  assistantDentist: 590,
  technician: 590,
  assistant: 490
}

export const travelDocumentTypes = {
  passport: {
    text: "Reisepass"
  },
  idCard: {
    text: "Personalausweis"
  }
}

export const conferenceSessions = [
  "Update Endodontologie",
  "Prothetikkonzepte für Praktiker",
  "Hygienemanagement",
  "Neues aus dem Steuerrecht",
  "Praxisabgabe",
  "Aktuelles aus der GOZ-Abrechnung",
  "Die Kieferorthopädische Frühbehandlung",
  "Digitalisierung in der Zahnarztpraxis",
  "PAR-Konzepte für die Praxis",
  "und weitere Themen ..."
]