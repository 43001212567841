import { string, object, boolean } from "yup"
import { returnOnEnvironment } from "./../utils/utils"

//
// Define Validation Schema
//

const validateName = string()
  .trim()
  .min(2, "Bitte füllen Sie das Feld aus.")
  .required("Bitte füllen Sie das Feld aus.")

const validateEmail = string()
  .trim()
  .required("Bitte geben Sie eine gültige Emailadresse ein.")
  .email("Bitte geben Sie eine gültige Emailadresse ein.")

export const cruiseBookingFormSchema = object()
  .shape({
    cabin: string()
      .required("Required"),
    
    passenger: object({
      surname: validateName,
      lastname: validateName,
      dateOfBirth: validateName
    }),

    billingAddress: object({
      name: validateName,
      street: validateName,
      postcodeCity: validateName,
      email: validateEmail,
      phoneNumber: validateName,
      mobilePhoneNumber: validateName
    }),

    transfer: object({
      type: 
        string().when('noBookingNeeded', (field, schema) => {
        return field ? schema.min(0) : schema.required().min(1)
      }),
      destination: 
        string()
        .trim()
        .when('type', (field, schema) => {
          return (field === "transferByBus" || field === "transferByTrain") ? schema.required().min(1) : schema 
        })
        .when('noBookingNeeded', (field, schema) => {
          return (field) ? schema.notRequired().min(0) : schema 
        }),
      noBookingNeeded: boolean()
    }),
});

//
// Here I define my forms initial values
//

const cabinInitialValues = returnOnEnvironment("yc1", "")

const transferInitialValues = {
  noBookingNeeded: false,
  destination: returnOnEnvironment("Berlin", ""),
  type: returnOnEnvironment("transferByFlight", ""),
}

const insuranceInitialValues = returnOnEnvironment(true, false)
const insurancePremiumProtectionInitialValues = returnOnEnvironment(true, false)
const drinksInitialValues = returnOnEnvironment("easy", "easy")

const passengerInitialValues = {
  lastname: returnOnEnvironment("Doe", ""),
  surname:  returnOnEnvironment("John", ""),
  dateOfBirth: returnOnEnvironment("31.01.2001", "")
}

const optionalPassengersInitialValues = [
  {
    lastname: returnOnEnvironment("Lou", ""),
    surname:  returnOnEnvironment("Mary", ""),
    dateOfBirth: returnOnEnvironment("01.01.1960", "")
  },
  {
    lastname: returnOnEnvironment("Kane", ""),
    surname:  returnOnEnvironment("Harry", ""),
    dateOfBirth: returnOnEnvironment("02.02.1960", "")
  },
  {
    lastname: returnOnEnvironment("Dalon", ""),
    surname:  returnOnEnvironment("Francis", ""),
    dateOfBirth: returnOnEnvironment("03.03.1960", "")
  }
]

const fellowTravellersInitialValues = [
  returnOnEnvironment("Max Cold", ""), 
  returnOnEnvironment("Lara Swasher", ""),
  returnOnEnvironment("Walt", ""), 
  returnOnEnvironment("Jenny", "")
]

const billingAddressInitialValues = {
  name: returnOnEnvironment("John Doe", ""), 
  street: returnOnEnvironment("Somewhere 25", ""),
  postcodeCity: returnOnEnvironment("12345 New Berlin", ""),
  phoneNumber: returnOnEnvironment("12345-phone", ""),
  mobilePhoneNumber: returnOnEnvironment("12345-mobile", ""),
  fax: returnOnEnvironment("12345-fax", ""),
  email: returnOnEnvironment("andiw@zedat.fu-berlin.de", "")
}

export const cruiseBookingFormInitialValues = {
  cabin:              cabinInitialValues,
  transfer:           {...transferInitialValues},
  insurance:          insuranceInitialValues,
  insurancePremiumProtection: insurancePremiumProtectionInitialValues,
  drinks:             drinksInitialValues,
  passenger:          {...passengerInitialValues},
  optionalPassengers: [...optionalPassengersInitialValues],
  fellowTravellers:   [...fellowTravellersInitialValues],
  billingAddress:     {...billingAddressInitialValues},
}