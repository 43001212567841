import { string, object } from "yup"
import { returnOnEnvironment } from "./../utils/utils"

//
// Define Validation Schema
//

const validateName = string()
  .trim()
  .min(2, "Pflichtangabe")
  .required("Pflichtangabe")

export const manifestSchema = object().shape({
    passenger: object({
      surname:  validateName,
      lastname: validateName,
      nationality:  validateName,
      dateOfBirth:  validateName,
      placeOfBirth: validateName
  }),

  travelDocument: object({
    type: string().required("Pflichtangabe").min(1, "Pflichtangabe"),
    number:     validateName,
    issueDate:  validateName,
    issuePlace: validateName,
    validUntil: validateName,
  }),

  emergency: object({
    name:  validateName,
    phone: validateName
  }),

  // emergency: object({
  //   name:  validateName,
  //   mobilePhoneNumber: validateName
  // }),
})

//
// Here I define my forms initial values
//

const reservationNumberInitialValue = returnOnEnvironment(123, "")
const cabinNumberInitialValue = returnOnEnvironment(456, "")

const passengerInitialValues = {
  surname:      returnOnEnvironment("Andi", ""),
  lastname:     returnOnEnvironment("Wei", ""),
  nationality:  returnOnEnvironment("de", ""),
  dateOfBirth:  returnOnEnvironment("31.12.1975", ""),
  placeOfBirth: returnOnEnvironment("Leizig", "")
}

const optionalPassengersInitialValues = [
  {
    surname:      returnOnEnvironment("Max", ""),
    lastname:     returnOnEnvironment("Cool", ""),
    nationality:  returnOnEnvironment("cn", ""),
    dateOfBirth:  returnOnEnvironment("31.12.1965", ""),
    placeOfBirth: returnOnEnvironment("Berlin", "")
  },
  {
    surname:      returnOnEnvironment("Horst", ""),
    lastname:     returnOnEnvironment("Baz", ""),
    nationality:  returnOnEnvironment("ru", ""),
    dateOfBirth:  returnOnEnvironment("31.12.1985", ""),
    placeOfBirth: returnOnEnvironment("Hamburg", "")
  },
  {
    surname:      returnOnEnvironment("Franz", ""),
    lastname:     returnOnEnvironment("Lust", ""),
    nationality:  returnOnEnvironment("fr", ""),
    dateOfBirth:  returnOnEnvironment("31.12.1980", ""),
    placeOfBirth: returnOnEnvironment("Wien", "")
  }
]

const passengerTravelDocumentInitialValues = {
  type:       returnOnEnvironment("passport", ""),
  number:     returnOnEnvironment("12345", ""),
  issuePlace: returnOnEnvironment("Berlin", ""),
  issueDate:  returnOnEnvironment("1.1.2010", ""),
  validUntil: returnOnEnvironment("1.1.2020", ""),
}

const optionalTravelDocumentsInitialValues = [
  {
    type:       returnOnEnvironment("passport", ""),
    number:     returnOnEnvironment("23456", ""),
    issuePlace: returnOnEnvironment("Hamburg", ""),
    issueDate:  returnOnEnvironment("1.1.2015", ""),
    validUntil: returnOnEnvironment("1.1.2025", ""),
  }, {
    type:       returnOnEnvironment("idCard", ""),
    number:     returnOnEnvironment("34567", ""),
    issuePlace: returnOnEnvironment("Leipzig", ""),
    issueDate:  returnOnEnvironment("1.1.2012", ""),
    validUntil: returnOnEnvironment("1.1.2022", ""),
  }, {
    type:       returnOnEnvironment("idCard", ""),
    number:     returnOnEnvironment("45678", ""),
    issuePlace: returnOnEnvironment("Wien", ""),
    issueDate:  returnOnEnvironment("1.1.2018", ""),
    validUntil: returnOnEnvironment("1.1.2028", ""),
  }
]

const emergencyInitialValues = {
  name:  returnOnEnvironment("Andi", ""),
  phone: returnOnEnvironment("1234", "")
}

export const manifestInitialValues = {
  cabinNumber:        cabinNumberInitialValue,
  reservationNumber:  reservationNumberInitialValue,
  passenger:          passengerInitialValues,
  optionalPassengers: optionalPassengersInitialValues,
  travelDocument:     passengerTravelDocumentInitialValues,
  optionalTravelDocuments: optionalTravelDocumentsInitialValues,
  emergency:          emergencyInitialValues
}