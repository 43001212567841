import React, { Fragment } from "react"
import { connect, getIn } from "formik"
import { Field } from "formik" 
import { Table } from "../../UI/Table/Table"
import HorizontalRule from "../../UI/HorizontalRule/HorizontalRule"
import { formatPriceWithPrefix } from "../../../utils/utils"
import { transfersModel } from "../../../model/model"

const Transfer = props => {
  const {values, errors} = getIn(props.formik)
  const disabled = (values.transfer && values.transfer.noBookingNeeded)
  return (
    <Fragment>
      <Table 
        hasError={errors.transfer}
        headline="Buchung der An- und Abreise"
      >
        { !disabled &&
          <Fragment>
            <tr className="fixed-height">
              <td width="15%" className="text-center">
                An- und Abreise
              </td>
              <td width="25%">
                <Field
                  component="select"
                  name="transfer.type"
                  className="full-width"
                  disabled={disabled}
                >
                  <option value="">
                    Bitte wählen ...
                  </option>
                  <TransferOptions values={values} />
                </Field>
              </td>
              <td>
                <TransferDestination values={values} />
                <TransferPrice values={values} />
              </td>
            </tr>
            <tr className="table-spacing-row" />
          </Fragment>
        }
        <tr>
          <td width="15%">
            <Field 
              type="checkbox" 
              name="transfer.noBookingNeeded"
              id="transfer.noBookingNeeded"
              className="radio-input justified"
              checked={values.transfer.noBookingNeeded}
            />
          </td>
          <td colSpan="2">
            <label htmlFor="transfer.noBookingNeeded">
              Ich wünsche keine Buchung der An- und Abreise.
            </label>
          </td>
        </tr>
      </Table>
      <TableFooter />
    </Fragment>
  )
}

const TransferOptions = ({values}) => {
  const options = transfersModel.map((transfer, index) => (
    <option 
      value={transfer.label}
      key={index}
    >
      {transfer.text}
    </option>
  ))
  return options
}

const TransferPrice = ({values = {}}) => {
  const filteredTransfer = (values && values.transfer && values.transfer.type) ? transfersModel.filter((transfer) => transfer.label === values.transfer.type) : null
  if (filteredTransfer) {
    const price = (filteredTransfer[0] && filteredTransfer[0].price) ? filteredTransfer[0].price : null
    
    return (
      <span style={{display: "inline-block", marginLeft: 8, width: "45%", verticalAlign: "middle"}}>
        { formatPriceWithPrefix(price, filteredTransfer[0].pricePrefix, filteredTransfer[0].priceSuffix) }
      </span>
    )
  }
  return null
}

const TransferDestination = ({values = {}}) => {
  const disabled = (values.transfer && values.transfer.noBookingNeeded)
  const filteredTransfer = (values && values.transfer && values.transfer.type) ? transfersModel.filter((transfer) => transfer.label === values.transfer.type) : null
  if (filteredTransfer) {
    const hasDestinationProvidedByUser = (filteredTransfer[0] && filteredTransfer[0].hasDestinationProvidedByUser) ? filteredTransfer[0].hasDestinationProvidedByUser : null
    if (hasDestinationProvidedByUser) {
      return (
        <Field
          type="text" 
          name="transfer.destination"
          placeholder="Transfer an/ab"
          className="input half-width has-margin-right"
          style={{verticalAlign: "middle"}}
          disabled={disabled}
        />
      )
    }
    return null
  }
  return null
}

const TableFooter = () => (
  <Fragment>
    <HorizontalRule />
  </Fragment>
)

export default connect(Transfer)